#page-banner {
	&.blog-signle-banner {
		.banner-top {
			.page-banner-title {
				.title {
					font-size: 50px;
					line-height: 66px;
					margin-bottom: 17px;
				}
			}
		}
	}
}

.banner-top {
	padding: 215px 0 125px;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;

	.page-banner-title {
		text-align: center;

		.title {
			font-size: 80px;
			line-height: 80px;
			color: #fff;
			margin: 0;
		}

		p {
			font-size: 40px;
			line-height: 80px;
			font-weight: 300;
			font-family: $font__heading-two;
			color: $color__theme;
			margin: 0;
		}
	}
}

.breadcrumb-wrapper {
	background: #f8f6f2;

	.breadcrumb-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.home-link {
		a {
			color: #fff;
			background: #222;
			padding: 19px 25px;
			display: block;

			&:hover {
				background: $color__theme;
			}
		}


	}
}

.site-breadcrumb {
	margin:0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		color: #aba8a4;
		margin-right: 20px;
		position: relative;


		&:after {
			content: '';
			position: absolute;
			right: -14px;
			top: 7px;
			height: 18px;
			width: 2px;
			background: #827f79;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		a {
			color: $color__body;

			&:hover {
				color: $color__theme;
			}
		}
	}
}

@media (max-width: 1024px) {

	.banner-top {


		.page-banner-title {


			.title {
				font-size: 60px;
				line-height: 60px;

			}

			p {
				font-size: 30px;
				line-height: 60px;

			}
		}
	}
}

@media (max-width: 991px) {

	.banner-top {
		padding: 150px 0 70px;

		.page-banner-title {


			.title {
				font-size: 50px;
				line-height: 50px;

			}

			p {
				font-size: 25px;
				line-height: 50px;

			}
		}
	}
}