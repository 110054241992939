.call-to-action {
	background-size: cover;
	background-position: center center;
	padding: 150px 0 143px;
	background-size: cover;

	.call-to-action-wrapper {
		display: flex;
		align-items: center;

		.content-left {
			flex: 2;

			h2 {
				color: #fff;
				font-size: 60px;
				font-weight: 700;
				line-height: 70px;
			}

			p {
				color: #fff;
				margin-top: 10px;
			}

			h4 {
				font-size: 40px;
				color: $color__theme;
				line-height: 70px;
				font-family: $font__heading-two;
				font-weight: 300;
			}
		}
		.gp-btn {
			padding: 11px 49px;
		}
	}
}

@media (max-width: 991px) {

	.call-to-action {
		padding: 100px 0;

		.call-to-action-wrapper {


			.content-left {


				h2 {
					color: #fff;
					font-size: 35px;
					line-height: 50px;

					br {
						display: none;
					}
				}



				h4 {
					font-size: 25px;
					line-height: 40px;

				}
			}

			.action-btn-inner {
				padding-left: 30px;
				align-items: center;
				display: flex;
			}
		}
	}


}

@media (max-width: 576px) {

	.call-to-action {
		padding: 100px 0;

		.call-to-action-wrapper {
			display: block;

			.content-left {
				margin-bottom: 30px;

			}

			.action-btn-inner {
				padding-left: 0;
			}
		}
	}
}