.about {
	padding: 214px 0 130px;

	.section-title {
		margin-bottom: 50px;
	}

	.about-content {
		padding-right: 50px;
	}

	p {
		margin-bottom: 30px;
	}

	.gp-btn {
		margin-top: 25px;
	}
}

.about-feature-image {
	position: relative;

	.img-one {
		transform: translateY(62px);
	}

	.img-two {
		position: absolute;
		right: -115px;
		top: -95px;
	}
}

.about-feature-thumb-two {
	margin: 50px 0 80px;
	border-bottom: 1px solid #e8e1d7;
}

.about-content-three {
	.section-title {
		.title {
			margin-bottom: 35px;
		}

		h4 {
			margin-bottom: 25px;
		}
	}

	p {
		margin-bottom: 45px;
	}
}

.about-three-thumb {
	position: relative;

	> div {
		position: absolute;
		top: 0;

	}

	.img-two {
		right: 70px;
		top: -77px;
	}
}

.food-bg {
	position: absolute;
	right: 0;
	bottom: 0;
}

.about-two {
	position: relative;
	padding: 112px 0 108px;

	.animate-element {
		position: absolute;
		top: -140px;
		left: -13%;
	}
}

#about-three {
	padding: 183px 0 151px;
	position: relative;
}

@media (max-width: 1440px) {
	.about-two .animate-element {
		left: -10%;
		max-width: 350px;
	}
}


@media (max-width: 1366px) {
	.about {
		padding: 140px 0 100px;
	}

	.about-feature-image {
		.img-one {
			max-width: 240px;
		}

		.img-two {
			right: 0;
			max-width: 270px;
			top: -40px;
		}
	}

	.about-two .animate-element {
		left: -11%;
		max-width: 280px;
	}

	.food-bg {
		max-width: 300px;
	}
}


@media (max-width: 1200px) {
	.about {
		padding: 140px 0 100px;

		.section-title {
			.title {
				br {
					display: none;
				}
			}
		}
	}

	.about-feature-image {
		.img-one {
			max-width: 200px;
		}

		.img-two {
			right: -25px;
			max-width: 250px;
			top: -40px;
		}
	}


}

@media (max-width: 991px) {
	.about  {
		padding: 80px 0 100px;

		.section-title {
			.title {
				br {
					display: none;
				}
			}
		}
	}

	.about-two {
		padding: 75px 0 80px;
	}


	.about-feature-image {
		.img-one {
			max-width: inherit;
		}

		.img-two {

			max-width: inherit;
			top: -40px;
		}
	}

	.about-two .animate-element {
		left: -11%;
		max-width: 180px;
	}


}

@media (max-width: 1200px)  {
	.about-three-thumb {
		.img-two {
			top: -50px;
			right: 40px;
		}
	}
}


@media (max-width: 991px) {
	.about-three-thumb {
		height: 650px;
	}

	.about-three-thumb > div {
		right: 180px;
	}

	#about-three {
		padding: 100px 0 80px;

		.section-title {
			margin: 0;
		}
	}

	.about-feature-thumb-two {
		margin: 40px 0 50px;
	}
}

@media (max-width: 768px) {
	.about-feature-image, .about-three-thumb {
		display: none;
	}

	.about {
		padding: 45px 0 40px;
	}

	#about-three {
		padding: 40px 0 50px;

	}


	.about-two .animate-element {
		display: none;

	}

	.about {
		.section-title {
			margin-bottom: 30px;
		}

		.about-content {
			padding-right: 0;
		}

		.gp-btn {
			margin-top: 0;
		}
	}




}