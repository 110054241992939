/*--------------------------------------------------------------
  ##  Page Loader
  --------------------------------------------------------------*/
@import "page-loader";

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
@import "header";

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
@import "banner";

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
@import "about";

/*--------------------------------------------------------------
  ##  Pricing
  --------------------------------------------------------------*/
@import "pricing";

/*--------------------------------------------------------------
  ##  Menu Tabs
  --------------------------------------------------------------*/
@import "menu-tabs";

/*--------------------------------------------------------------
  ##  Call To Action
  --------------------------------------------------------------*/
@import "call-to-action";

/*--------------------------------------------------------------
  ##  Download
  --------------------------------------------------------------*/
@import "download";

/*--------------------------------------------------------------
  ##  Team
  --------------------------------------------------------------*/
@import "team";

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
@import "blog";

/*--------------------------------------------------------------
  ##  Widget
  --------------------------------------------------------------*/
@import "widget";

/*--------------------------------------------------------------
  ##  Testimonial
  --------------------------------------------------------------*/
@import "testimonial";

/*--------------------------------------------------------------
  ##  Food
  --------------------------------------------------------------*/
@import "food";

/*--------------------------------------------------------------
  ##  Newsletter
  --------------------------------------------------------------*/
@import "newsletter";

/*--------------------------------------------------------------
  ##  Gallery
  --------------------------------------------------------------*/
@import "gallery";

/*--------------------------------------------------------------
  ##  Map
  --------------------------------------------------------------*/
@import "map";

/*--------------------------------------------------------------
  ##  Instagram
  --------------------------------------------------------------*/
@import "instagram";

/*--------------------------------------------------------------
  ##  Page Banner
  --------------------------------------------------------------*/
@import "page-banner";

/*--------------------------------------------------------------
  ##  Contact
  --------------------------------------------------------------*/
@import "contact";

/*--------------------------------------------------------------
  ##  Error Page
  --------------------------------------------------------------*/
@import "error";

/*--------------------------------------------------------------
  ##  Footer
  --------------------------------------------------------------*/
@import "footer";