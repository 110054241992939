/*!
  Theme Name: Cafedia
  Theme URI: http://html.pixelsigns.co/cafedia
  Author: PixelSigns
  Author URI: http://www.pixelsigns.co/
  Description: Theme Description
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Text Domain: themename
  Tags: food,resturant,business,startup
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
  	## Page Loader
    ## Header
    ## Banner
    ## About
    ## Pricing
    ## Menu Tabs
    ## Call To Action
    ## Download
    ## Team
    ## Blog
    ## Widget
    ## Testimonial
    ## Food
    ## Newsletter
    ## Gallery
    ## Map
    ## Instagram
    ## Page Banner
    ## Contact
    ## Footer

  --------------------------------------------------------------*/
@import "generated/icons";
@import "base/variables";
@import "utils/mixins";
@import "base/base";
@import "components/components";
@import "elements/elements";

