/* Fool-proof @font-face */
/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {

	@font-face {
		font-family: $font-family;
		src: url('#{$file-path}.eot');
		src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
		url('#{$file-path}.woff') format('woff'),
		url('#{$file-path}.ttf') format('truetype'),
		url('#{$file-path}.svg##{$font-family}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}

// @mixin flex($flex: flex, $wrap: wrap,) {
//     display: $flex;
//     flex-wrap: $wrap;
// }

@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}


@mixin linx ($link, $visit, $hover, $active) {
	a {
		color: $link;
		&:visited {
			color: $visit;
		}
		&:hover {
			color: $hover;
		}
		&:active {
			color: $active;
		}
	}
}

@mixin fontsize($size) {
  font-size: $size * 1px;
  font-size: $size * 0.1rem;
}

@mixin lineheight($size) {
  line-height: $size * 1px;
  line-height: $size * 0.1rem;
}





//
//  Rem
//––––––––––––––––––––––––––––––––––––––––––––––––––
$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
	@if $separator == "comma" or $separator == "space" {
		@return append($list, null, $separator);
	}

	@if function-exists("list-separator") == true {
		@return list-separator($list);
	}

  // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
  $test-list: ();
  @each $item in $list {
  	$test-list: append($test-list, $item, space);
  }

  @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
	font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
	$result: ();
	$separator: rem-separator($values);

	@each $value in $values {
		@if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
			$result: append($result, $value / 1rem * $rem-baseline, $separator);
		} @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
			$result: append($result, $value / $rem-baseline * 1rem, $separator);
		} @else if type-of($value) == "list" {
			$value-separator: rem-separator($value);
			$value: rem-convert($to, $value...);
			$value: rem-separator($value, $value-separator);
			$result: append($result, $value, $separator);
		} @else {
			$result: append($result, $value, $separator);
		}
	}

	@return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
	@if $rem-px-only {
		@return rem-convert(px, $values...);
	} @else {
		@return rem-convert(rem, $values...);
	}
}

@mixin rem($properties, $values...) {
	@if type-of($properties) == "map" {
		@each $property in map-keys($properties) {
			@include rem($property, map-get($properties, $property));
		}
	} @else {
		@each $property in $properties {
			@if $rem-fallback or $rem-px-only {
				#{$property}: rem-convert(px, $values...);
			}
			@if not $rem-px-only {
				#{$property}: rem-convert(rem, $values...);
			}
		}
	}
}


//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);


//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}