body {
	font-family: $font__primary;
	font-size: 20px;
	line-height: 32px;
	color: $color__body;
}


svg {
	display: block;
}

a, button, input, textarea, button {
	transition: all 0.3s ease-in-out;
}

a {
	color: #222;
}


input, textarea {
	width: 100%;
	padding: 10px 12px;
	outline: 0;

	&::placeholder {
		color: #9e9c97;
		transition: all 0.3s ease-in-out;
		transform-origin: 0 50%;
	}

	&:focus {
		background: #fff;
		box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.2);

		&::-webkit-input-placeholder {
			-moz-transform: scale(0.8);
			-ms-transform: scale(0.8);
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
		}

	}
}

button {
	cursor: pointer;
	outline: 0;
	background: $color__theme;
	border: 0;
	color: #fff;
}

a,
a:hover {
	text-decoration: none;
}

a:hover {
	color: $color__theme;
}

h1,h2,h3,h4,h5,h6 {
	font-family: $font__heading;
	margin-top: 0;
	font-weight: 600;
	color: $color__heading;
}


img {
	max-width: 100%;
	height: auto;
}

section {
	position: relative;
}

.section-bg {
	background: $color__bg;
}

.btn-container {
	margin-top: 30px;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}


.section-padding {
	padding: 112px 0 110px;
}

.pl-30 {
	padding-left: 30px;
}

.fade {
	transition: opacity .25s linear;
}

.pb-100 {
	padding-bottom: 90px;
}

.pb-120 {
	padding-bottom: 120px;
}

.mt-60 {
	margin-top: 60px !important;
}

.mt-50 {
	margin-top: 50px !important;
}


.mb-30 {
	margin-bottom: 30px;
}

.wrapper-padding {
	padding: 80px 0 120px;
}

/*
Flaticon icon font: Flaticon
Creation date: 04/06/2018 14:38
*/

@font-face {
	font-family: "Flaticon";
	src: url("./Flaticon.eot");
	src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}


.fi:before{
	display: inline-block;
	font-family: "Flaticon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}


.flaticon-dessert:before { content: "\f100"; }
.flaticon-food:before { content: "\f101"; }
.flaticon-alcohol:before { content: "\f102"; }
.flaticon-dish:before { content: "\f103"; }
.flaticon-cutlery:before { content: "\f104"; }
.flaticon-google-play:before { content: "\f105"; }
.flaticon-apple-logo:before { content: "\f106"; }

.section-title {
	text-align: center;
	position: relative;
	margin-bottom: 55px;

	.sub-title {
		display: block;
		font-size: 40px;
		font-weight: 300;
		line-height: 1.2;
		color: $color__theme;
		font-family: $font__heading-two;
		margin-bottom: 5px;
	}

	.title {
		font-size: 50px;
		font-weight: 700;
		color: $color__heading;
		position: relative;
		line-height: 1.2;
		margin: 0;

		span {
			font-weight: 400;
		}
	}


}

.home-border {
	padding: 30px;
}

@media (max-width: 1200px) {
	.section-title {
		margin-bottom: 55px;

		.sub-title {
			line-height: 50px;
			font-size: 30px;

		}

		.title {
			font-size: 40px;
			line-height: 50px;

		}
	}

	body {
		font-size: 18px;
		line-height: 28px;
	}
}

@media (max-width: 991px) {
	.pl-30 {
		padding-left: 0;
	}

	.pb-100 {
		padding-bottom: 50px;
	}
}

@media (max-width: 576px) {
	.section-padding {
		padding: 70px 0 50px;
	}

	.section-title {

		.title {
			font-size: 30px;
			line-height: 40px;

			br {
				display: none;
			}

		}
	}

	.home-border {
		padding: 15px;
	}

	.pb-100 {
		padding-bottom: 20px;
	}
}

@media (max-width: 768px) {
	.section-padding {
		padding: 40px 0 50px;
	}
}


[data-rellax-container] [data-rellax] {
    transition: opacity .3s ease-out;
    opacity: 0;
}

[data-rellax-container] {
    overflow: hidden;
}

[data-rellax] {
    will-change: transform, opacity;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.is--loaded [data-rellax-container] [data-rellax] {
    opacity: 1;
}

[data-rellax] {
	transform: translate3d(0,0,0);
	will-change: transform;
	background-color: #444;
}

.mw-none {
	max-width: none;
}
