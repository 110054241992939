#download {
	padding: 160px 0 170px;
}

.download-content {
	.title {
		font-size: 50px;
		margin-bottom: 20px;

	}

	p {
		margin-bottom: 45px;
	}
}

.download-thumb {
	position: relative;
	margin-top: -70px;

	> div {
		position: absolute;
		top: 0;
	}

	.img-one {
		left: -50px;
	}

	.img-two {
		right: 22px;
	}
}

.newsletter-form {
	margin-bottom: 60px;

	.newsletter-inner {
		display: flex;
		overflow: hidden;
	}

	.form-result {
		margin-top: 10px;
		border-radius: 30px;
		position: absolute;
		max-width: 100%;
	}

	input {
		background: #f8f6f2;
		border: 0;
		outline: 0;
		width: 100%;
		color: $color__heading;
		padding: 12px 20px;
		height: 70px;
		outline: 0;
		font-size: 20px;
		border-radius: 0;

		&::placeholder {
			color: #b0b0b0;

		}

		&:focus {
			box-shadow: none;
			color: $color__heading;
			background: #f8f6f2;
		}
	}

	.newsletter-submit {
		background: #fff;
		border: 0;
		outline: 0;
		background: $color__heading;
		color: #fff;
		font-weight: 700;
		padding: 10px 38px;
		font-size: 14px;
		text-transform: uppercase;

		i {
			display: none;
		}

		&:hover {
			background: $color__theme;
		}
	}
}



.store-btns {
	.store-btn {
		margin-right: 20px;
		transition: all 0.3s ease-in-out;
		display: inline-block;
		border: 2px solid $color__heading;
		color: $color__heading;
		padding: 7px 18px;
		font-size: 16px;
		text-align: left;
		font-weight: 700;
		text-transform: uppercase;

		span {
			display: block;
			font-size: 16px;
			font-weight: 400;
			text-transform: capitalize;

		}

		.btn-text {
			margin-left: 40px;
			line-height: 21px;
		}

		i {
			float: left;
			font-size: 30px;

			&:before {
				line-height: 42px;
			}
		}

		&:hover {
			background: $color__theme;
			border-color: $color__theme;
			color: #fff;

		}

		&.active {
			background: $color__theme;
			border-color: $color__theme;
			color: #fff;

			&:hover {
				background: transparent;
				border-color: $color__heading;
				color: $color__heading;
			}
		}
	}
}

@media (max-width: 1200px) {
	.newsletter-form input {
		width: 75%;
	}
}

@media (max-width: 991px) {
	#download {
		padding: 70px 0 80px;
	}

	.download-content {
		.title {
			font-size: 38px;
		}
	}

	.newsletter-form {
		margin-bottom: 30px;
	}

	.store-btns {
		.store-btn {
			padding: 7px 12px;
			margin-right: 10px;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			.btn-text {
				margin-left: 33px;
			}

			i {
				font-size: 24px;
			}
		}
	}

	.download-content p {
		br {
			display: none;
		}
	}

	.download-thumb .img-one {
		max-width: 280px;
	}
}

@media (max-width: 768px) {


	.download-thumb {
		height: 400px;
		width: 300px;
		margin: 0 auto;
	}

	.img-two {
		max-width: 250px;
		right: auto;
		left: 0;
	}

	#download {
		padding: 60px 0 50px;
	}


	.newsletter-form .newsletter-submit {
		padding: 10px 20px;
	}
}