.error-page {
	text-align: center;
	padding: 155px 0 190px;
	background-size: cover;
	background-position: center center;

	h1.error {
		font-size: 400px;
		font-weight: 700;
		line-height: 1;
	}

	h2 {
		font-size: 80px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 40px;
	}

	p {
		margin-bottom: 50px;
	}
}

@media (max-width: 1200px) {

	.error-page {
		padding: 100px 0 130px;

		h1.error {
			font-size: 250px;
		}
	}

	.error-page h2 {
		font-size: 50px;
	}
}

@media (max-width: 991px) {
	.error-page {
		padding: 60px 0;

		h1.error {
			font-size: 180px;
		}

		h2 {
			font-size: 40px;
		}
	}


}

@media (max-width: 768px) {

	.error-page {
		h1.error {
			font-size: 130px;
		}

		h2 {
			font-size: 30px;
		}

		p {
			br {
				display: none;
			}
		}
	}

}

@media (max-width: 576px) {
	.error-page h1.error {
		font-size: 80px;
	}

	.error-page h2 {
		font-size: 30px;
	}
}