#testimonial {
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

#testimonial, #testimonial-dark {
	padding: 117px 0 117px;
}

.testimonial-content {
	.testi-author {
		padding-right: 80px;
		margin-bottom: 5px;

		.testi-avatar {
			height: 70px;
			width: 70px;
			border-radius: 50%;
			margin-left: auto;
			margin-bottom: 30px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				border: 1px solid $color__theme;
				top: -13px;
				bottom: -13px;
				left: -13px;
				right: -13px;
				border-radius: 50%;
			}

			img {
				border-radius: 50%;
			}
		}

		.user-details {
			.name {
				color: $color__heading;
				font-size: 24px;
				font-weight: 700;
				margin: 0;
				line-height: 32px;
			}

			.position {
				font-weight: 400;
				font-size: 20px;

			}
		}
	}

	.content {

		.heading {
			font-size: 40px;
			color: $color__theme;
			font-weight: 300;
			font-family: $font__heading-two;
			margin-bottom: 15px;
		}

		p {
			color: rgba(50,51,51,.8);
			font-size: 30px;
			font-weight: 700;
			line-height: 1.5;
			margin: 0;
			color: $color__heading;
		}
	}

	&.content-two {
		.testi-author {
			max-width: 280px;
			margin: 30px auto 0;
			display: flex;
			flex-wrap: wrap;
			padding-right: 0;
			min-height: 100px;
			align-items: center;

			.testi-avatar {
				margin: auto;
				margin-right: 20px;
			}

			.user-details {
				text-align: left;
				flex: 1;
				margin-left: 10px;
			}
		}


	}


}

.section-dark {
	.swiper-pagination-bullet{
		background: #fff;
		opacity: 1;

		&.swiper-pagination-bullet-active {
			background: #fff;

			&:before {
				border-color: #fff;
			}
		}
	}

}

.testi-slider-nav {
	z-index: 999;
	position: absolute;
	top: 50%;
	width: 100%;
	height: 50px;

	%slider-nav {
		height: 50px;
		width: 50px !important;
		text-align: center;
		line-height: 50px;
		color: $color__heading;
		border: 1px solid #999;
		border-radius: 50%;
		display: block;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		position: absolute;
		top: 0;
		font-size: 20px;
	}
	.slider-prev, .slider-next {
		@extend %slider-nav;

		&:hover {
			background: $color__theme;
			color: #fff;
			border-color: $color__theme;
		}
	}

	.slider-next {
		right: 0;
	}
}


.testimonial-three {
	position: relative;

	.testi-slider-nav {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		left: 0;
		height: 50px;

		.slider-prev {
			left: 0;
		}

		.slider-next {
			right: 0;
		}
	}
}

.rating {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		color: #ffaf0f;
		font-size: 14px;
	}
}

#testimonial-two {
	padding: 100px 0 116px;
	background-attachment: fixed;
	background-size: cover;
}

.testimonial-wrapper {
	.slider-pagi-inner {
		margin-top: 25px;

		.swiper-pagination {
			transform: translateX(-71%);
		}

	}

	&-two {
		.slider-pagi-inner {
			margin-top: 60px;
		}
	}
}

.testimonial-dark {
	.testimonial-content {
		.testi-author {

			.testi-avatar {

				&:before {
					content: '';
					position: absolute;
					border: 1px solid $color__theme;
					top: -13px;
					bottom: -13px;
					left: -13px;
					right: -13px;
					border-radius: 50%;
				}

				img {
					border-radius: 50%;
				}
			}

			.user-details {
				.name {
					color: #fff;
				}
			}
		}

		.content {

			p {
				color: #fff;
			}
		}




	}
}

@media (max-width: 1200px) {
	.testimonial-content {
		.content {
			p {
				font-size: 28px;
				line-height: 40px;
			}
		}

		.testi-author{
			padding-right: 20px;
		}
	}
}


@media (max-width: 991px) {
	.testimonial-content {
		.content {
			p {
				font-size: 28px;
				line-height: 40px;
			}
		}

		.testi-author{
			padding-right: 20px;
		}
	}

	#testimonial {
		padding: 80px 0;
	}

	#testimonial-two {
		padding: 70px 0 80px;
	}
}

@media (max-width: 768px) {
	.testimonial-content {
		.testi-author {
			margin: 20px 0 30px;
			padding: 0;

			&.text-right {
				text-align: center !important;
			}

			.testi-avatar {
				margin: 0 auto 30px;
			}
		}

		.content {
			text-align: center;

			.heading {
				line-height: 50px;
			}
		}
	}

	.testimonial-wrapper .slider-pagi-inner .swiper-pagination {

		transform: translateX(-50%);
	}

	#testimonial {
		padding: 50px 0;
	}

	.testimonial-content {
		.content {
			p {
				font-size: 20px;
				line-height: 32px;
			}
		}
	}

	#testimonial-two {
		padding: 40px 0 50px;
	}

	.testimonial-wrapper-two .slider-pagi-inner {
		margin-top: 30px;
	}
}

