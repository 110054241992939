#site-footer {
	background: #222222;

	.footer-nner {
		padding: 120px 0 90px;
		.footer-logo {
			display: inline-block;
			margin-bottom: 30px;
		}
	}

	.widget {
		margin: 0;

		.widget-title {
			font-size: 24px;
			color: #fff;
			font-weight: 700;
			margin-bottom: 35px;
			border-bottom: 0;
			padding: 0;
		}
	}

	.footer-menu {
		margin-bottom: 0;
		padding: 0;
		list-style: none;

		li {


			a {
				color: #b0b0b0;
				position: relative;
				font-weight: 500;
				font-size: 20px;
				line-height: 40px;
				position: relative;


				&:after {
					position: absolute;
					content: '';
					display: block;
					bottom: 0;
					height: 1px;
					left: 0;
					width: 100%;
					background: $color__theme;
					transform-origin: right center;
					transform: scale(0, 1);
					transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85), -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
				}

				&:hover {
					color: $color__theme;

					&:after {
						transform-origin: left center;
						transform: scale(1, 1);
					}
				}
			}

			&:first-child {
				a {
					&:before {
						display: none;
					}
				}
			}
		}
	}

	.title {
		color: #b0b0b0;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 10px;
	}



	.footer-newsletter-form {

		.form-control {
			background: #fff;
			padding: 10px 20px;
			margin-bottom: 20px;
			border-radius: 0;
			outline: 0;
			height: 60px;

			&::placeholder {
				color: #b0b0b0;
				font-size: 20px;
				font-weight: 400;
			}

			&:hover, &:focus {
				box-shadow: none;
				border-color: $color__theme;
			}
		}

		.newsletter-submit {
			border: 1px solid rgb(104, 104, 104);
			color: #b0b0b0;
			font-size: 14px;
			text-transform: uppercase;
			background: transparent;
			padding: 5px 28px;
			// margin-bottom: 44px;

			&:hover {
				background: $color__theme;
				color: #fff;
				border-color: $color__theme;
			}
		}
	}

	.site-info {
		border-top: 1px solid rgb(67, 67, 67);;
		padding: 21px 0;
		text-align: center;

		.copy-right {
			margin-bottom: 0;
			color: #838383;
			font-size: 18px;

			a {
				color: #b0b0b0;
				margin-left: 5px;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}
}

.footer-social-link {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin: 0 5px;

		a {
			font-size: 16px;
			display: block;
			border-radius: 50%;
			color: #827f79;

			&:hover {
				color: $color__theme;
			}
		}
	}
}

.footer-top {
	background:	#faf8f5;
	padding: 43px 0;

	.footer-menu {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			margin-right: 40px;

			a {
				font-size: 14px;
				text-transform: uppercase;
				color: $color__heading;
				font-weight: 700;
				position: relative;

				&:after {
					position: absolute;
					content: '';
					display: block;
					bottom: 0;
					height: 1px;
					left: 0;
					width: 100%;
					background: $color__theme;
					transform-origin: right center;
					transform: scale(0, 1);
					transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85), -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
				}

				&:hover {
					color: $color__theme;

					&:after {
						transform-origin: left center;
						transform: scale(1, 1);
					}
				}
			}

		}
	}
}

.site-info-two {
	padding: 43px 0;

	p {
		margin: 0;
		font-size: 18px;
	}

	.footer-social-link {
		margin-left: 30px;
	}

	.footer-logo {
		display: block;
		background:	#fff;
		position: relative;
		text-align: center;

		img {
			position: relative;
			z-index: 	222;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: url(../../media/background/shape.png);
			top: -113px;
			width: 230px;
			height: 135px;
			left: -30px;
		}


		&:before {
			content: '';
			background: #fff;
			height: 180px;
			width: 240px;
			left: -28px;
			top: -83px;
			-webkit-box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.1);
			box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.1);
			position: absolute;
			z-index: 1;
		}
	}
}

.footer-dark {
	.footer-top {
		background: #242424;

		.footer-menu {
			li {
				a {
					color: #fff;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}

	.footer-logo {
		&:before {
			background: #383838;
		}

		&:after {
			content: '';
			position: absolute;
			background-image: url(../../media/background/shape2.png);
			top: -113px;
			width: 230px;
			height: 135px;
			left: -30px;
		}
	}

	.copy-right {
		a {
			color: $color__theme;

			&:hover {
				color: darken($color__theme, 10%);
			}
		}
	}
}

@media (max-width: 1200px) {
	.site-info-two {
		.footer-logo {
			&:before {
				width: 200px;
				left: -20px;
			}
		}


	}

	.site-info-two p {
		font-size: 16px;
	}
}


@media (max-width: 1024px) {
	.site-info-two .footer-logo:after {
		top: -108px;
		width: 179px;
		height: 135px;
		left: -30px;
	}
}

@media (max-width: 991px) {
	.site-info-two .footer-logo:before {
		width: 155px;
	}

	.site-info-two .footer-social-link {
		margin-left: 10px;
	}

	.site-info-two .footer-logo:after {
		display: none;
	}



	.footer-top .footer-menu li {
		margin-right: 25px;
	}

	#site-footer {
		.footer-nner {
			padding: 80px 0 20px;

		}
	}
}

@media (max-width: 768px) {
	.site-info-two {
		text-align: center;

		.copy-right  {
			margin-bottom: 30px;
			font-size: 20px;
		}

		.footer-logo {

			max-width: 210px;
			padding: 21px 0;
			margin: 0 auto 30px;

			&:before {
				display: none;
			}
		}

		.follow-us{
			&.d-flex {
				display: block !important;
			}

			span {
				display: inline-block;
			}
		}
	}

	#site-footer {
		.footer-nner {
			padding: 40px 0 20px;

		}
	}

	#footer-two {
		&.footer-dark {
			.footer-logo {
				background: #383838;
			}
		}

		.footer-menu {
			text-align: center;

			&.text-right {
				text-align: center !important;
			}
		}
	}


}

.site-header.top-30 {
	top: 30px;
}