
.header-inner {
	position: relative;
}

.site-header {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	transition: all 0.5s ease-in-out;



	&.gp-header-fixed {
		top: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 999999;
		transform: translateY(-100%);
		box-shadow: rem(0px 5px 20px rgba(0, 0, 0, 0.1));
		transition: all 0.5s ease-in-out;
		background: #fff;

		.header-inner {

			.site-logo {
				padding: rem(18px 0);

				.logo-sticky {
					display: block;
				}

				.logo-main {
					display: none;
				}
			}
		}

		.site-main-menu {

			li {
				padding: rem(28px 0);
				a {
					color: #333;

					&:hover, &.current_page  {
						color: $color__theme;

						&:before, &:after {
							background: $color__theme;
						}
					}
				}

				&.menu-item-has-children {
					&:after {
						color: $color__heading;
					}
				}

			}

		}


		.site-nav {
			.right-menu {
				&:before {

					background: rgba(0,0,0,0.2);

				}

				.search-btn {
					color: $color__heading;
				}
			}

			.nav-btn {
				background: $color__heading;

				&:hover {
					color: #fff;

					&:before {
						background: $color__theme;
					}
				}
			}
		}


		.site-logo {
			.main-logo {
				display: none;
			}

			.sticky-logo {
				display: block;
			}
		}

		&.top-30 {
			top: 0;
		}

		&.sticky {
			transform: translateY(0);
		}
	}

	&.static-header {
		position: relative;
		background-color: #fff;
		box-shadow: 0px 4px 10px 0px rgba(68, 43, 3, 0.08);
	}

}




/* Sticky Meu */
.gp-hidden-menu {
	top: -100% !important;
	animation: headerSlideDown 0.3s ;
	transition: all 0.3s ease-in-out;
}

.logo-sticky {
	display: none;
}

@keyframes headerSlideDown {
	0% {
		margin-top: -100%;
	}
	100% {
		margin-top: 0;
	}
}



.toggle-menu {
	display: none;
}





@media (max-width: 992px) {
	.site-nav {
		position: fixed;
		left: -100%;
		top: 0;
		height: 100vh;
		background: #222;
		z-index: 9999;
		padding: 70px 40px 50px;
		width: 350px;
		transition: all 0.5s ease-in-out;

	}

	.site-header {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		padding: 10px 0;

		&.gp-header-fixed {
			.site-main-menu {
				li {
					padding: 7px 0;

					a {
						color: #bbbbbb;
					}

					&.menu-item-has-children {
						&:after {
							color: #fff;
						}
					}
				}
			}
		}

		&.header-two {
			.header-inner {
				width: 90%;
				position: relative;
				margin: 0 auto;
			}


			.toggle-menu {
				.bar {
					background: $color__heading;
				}
			}
		}


		&.static-header {

			.toggle-menu .bar {
				background: #222;
			}
		}
	}

	.close-menu {
		display: block;
		position: absolute;
		top: 0;
		text-align: right;
		width: 100%;
		left: 0;
		padding: 2px;
		background: #292929;
		box-shadow: -5px 10px 26px rgba(0,0,0,0.2);

		i {
			color: #fff;
			font-size: 35px;
			margin-top: 8px;
			margin-right: 4px;

			&:before {
				line-height: 1.2;
			}
		}
	}

	.site-main-menu  {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			padding: 7px 0;
			&:not(:last-child) {
				border-bottom: 1px solid rgba(255, 255, 255, 0.05);
			}

			&.menu-item-has-children {
				position: relative;


				&:after {
					content: "\f067";
					position: absolute;
					right: 0;
					top: 5px;
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					font-size: rem(10px);
					color: #fff;
					z-index: -1;
				}

				&.open {
					&:after {
						content: "\f068";
					}
				}

			}
			a {
				color: #bbbbbb !important;
				display: block;

				&.current_page, &:hover {
					color: $color__theme !important;
				}
			}

			.sub-menu {
				padding-left: 15px;

				li {
					a {
						font-size: 18px;
					}
				}
			}
		}


		ul {
			display: none;
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	.open-menu {
		.site-nav {
			left: 0;
		}
	}

	.right-menu {
		display: none;
	}



	.toggle-menu {
		position: absolute;
		right: 0;
		width: 26px;
		top: 18px;
		display: block;
		height: 24px;

		.bar {
			width: 100%;
			height: 1px;
			display: block;
			margin-bottom: 7px;
			background: #fff;
		}
	}

	.site-header {
		&.gp-header-fixed {
			.header-inner {
				.site-logo {
					padding: 0;
				}
			}

			.toggle-menu {
				.bar {
					background: $color__heading;
				}
			}
		}
	}



}

.mask-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 3333;
	top: 0;
	left: 0;
}


@media (min-width: 992px) {
	.site-header {


		.header-inner {
			display: flex;
			align-items: center;

			.site-logo {
				padding: rem(23px 0);
				transition: all 0.3s ease-in-out;

				a {
					display: block;
					max-width: 150px;

					img {
						width: 100%;
					}
				}
			}

			.site-nav {
				margin-left: auto;
			}
		}

		.site-main-menu {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;

			li {
				margin: rem(0 21px);
				position: relative;
				padding: rem(48px 0);
				border: 0 !important;
				transition: all 0.3s ease-in-out;

				&:last-child {
					margin-right: 0;
				}

				> a {
					color: #fff;
					font-size: rem(14px);
					font-weight: 700;
					text-transform: uppercase;
					position: relative;


					&:after {
						position: absolute;
						content: '';
						display: block;
						bottom: 0;
						height: 1px;
						left: 0;
						width: 100%;
						background: $color__theme;
						transform-origin: right center;
						transform: scale(0, 1);
						transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85), -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
					}

					&:hover, &.current_page {
						color: $color__theme;

						&:after {
							transform-origin: left center;
							transform: scale(1, 1);
						}
					}
				}

				&.menu-item-has-children {

					&:after {
						content: "\e64b";
						position: absolute;
						right: rem(-18px);
						top: 50%;
						transform: translateY(-50%);
						font-family: 'themify';
						font-size: rem(10px);
						font-weight: 700;
						color: #fff;
					}
				}

				.sub-menu {
					display: block;
					margin: 0;
					padding: rem(15px 0);
					list-style: none;
					background: #fff;
					box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
					position: absolute;
					top: 110%;
					left: 0;
					min-width: rem(250px);
					visibility: hidden;
					opacity: 0;
					transition: all 0.3s ease-in-out;
					z-index: 999999;

					li {
						display: block;
						margin: 0;
						padding: rem(0 25px);

						&.menu-item-has-children {

							&:after {
								content: "\e649";
								right: rem(25px);
								color: #333;
							}

							.sub-menu {
								left: 100%;
								top: 0;
								visibility: hidden;
								opacity: 0;
							}

							&:hover {
								.sub-menu {
									top: rem(-15px);
									visibility: visible;
									opacity: 1;
								}
							}
						}


						a {
							display: block;
							padding: rem(5px 0);
							color: #333;
							font-size: rem(14px);

							&:after {
								display: none;
							}

							&:hover {
								color: $color__theme
							}
						}

						&:last-child {
							a {
								border-bottom: 0;
							}
						}
					}
				}

				&:hover {
					.sub-menu {
						opacity: 1;
						visibility: visible;
						top: 100%;
					}
				}
			}
		}

		.site-nav {
			display: flex;
			align-items: center;

			.right-menu {
				margin-left: 20px;
				padding-left: 20px;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					height: 70%;
					width: 1px;
					background: rgba(255,255,255,0.2);
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}

				.search-btn {
					font-size: 18px;
					color: #fff;
					cursor: pointer;
					position: absolute;
					top: 50%;
					transform: translateY(-48%);
				}

				.nav-btn {
					margin-left: 40px;
				}
			}
		}

		&.header-two {
			.header-inner {
				max-width: 1560px;
				padding: 0 30px;
				margin: 0 auto;

			}

			.site-main-menu {
				li {
					> a {
						color: $color__heading;

						&:after {
							background: $color__theme;
						}

						&:hover {
							color: $color__theme;
						}
					}

					&.menu-item-has-children {
						&:after {
							color: $color__heading;
						}
					}
				}
			}

			.site-nav {
				.right-menu {
					&:before {
						height: 25px;
						background: rgba(0,0,0,0.2);

					}
					.search-btn {
						color: $color__heading;
					}
				}
			}
		}

		&.top-30 {
			top: 30px;
		}

		&.static-header {
			.site-main-menu {
				li {
					padding: rem(40px 0);

					> a {
						color: #222;

						&:hover {
							color: $color__theme;
						}
					}

					&.menu-item-has-children:after {
						color: #222;
					}
				}
			}

			.site-nav {
				.right-menu {

					&:before {
						background: rgba(0,0,0,0.5);
					}
					.search-btn {
						color: #222;
					}

					.gp-btn.nav-btn{
						&:before {
							background: #222;
						}

						&:hover {
							color: #fff;
						}
					}
				}
			}

			.toggle-menu .bar {
				background: #222;
			}

			&.gp-header-fixed {
				position: fixed;

				.site-main-menu > li {
					padding: rem(20px 0);
				}
			}

		}
	}

	.close-menu {
		display: none;
	}
}



@media (min-width: 992px) and (max-width: 1200px) {
	.site-header {
		.site-main-menu {
			li {
				margin: 0 rem(15px);

				> a {
					font-size: 13px;
				}
			}
		}

		.site-nav {
			.right-menu {
				padding-left: 15px;

				.nav-btn {
					padding: 10px 15px;
					margin-left: 30px;
				}
			}

		}
	}
}


@media (max-width: 768px) {
	.site-nav {
		width: 100%;
	}

	.site-logo {
		a {
			max-width: 120px;
			display: block;
		}
	}

	.site-header {
		&.top-30 {
			top: 15px;
		}
	}

	.toggle-menu {
		top: 11px;
		right: 15px;
	}

	.header-inner {
		padding: 0 15px;

	}


}

/* Back To Top */
.return-to-top {
	position: fixed;
	bottom: rem(-40px);
	right: rem(30px);
	width: rem(42px);
	height: rem(42px);
	line-height: rem(42px);
	text-align: center;
	cursor: pointer;
	z-index: 998;
	border-radius: 3%;
	opacity: 0;
	transition: bottom .5s ease, opacity .5s ease;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: transparent;
		opacity: 1;
		display: block;
		transform: scale(1);
		transition: all .3s ease;
		border-radius: inherit;
		transition: transform .5s ease, opacity .6s ease;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		background: rgba(0,0,0,0.25);
		-webkit-box-shadow: 0px 0px 0px 0px transparent;
		box-shadow: 0px 0px 0px 0px transparent;
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: all .3s ease;
		-moz-transition: all .3s ease;
		transition: all .3s ease;
		border-radius: inherit;
	}

	> i {
		position: relative;
		overflow: hidden;
		font-size: rem(12px);
		width: inherit;
		height: inherit;
		line-height: inherit;
		display: block;
		color: transparent;
		text-shadow: rem(0px 0px #fff, 0px 50px #fff);
		transition: text-shadow .2s ease;
		z-index: 1;
	}

	&:hover {
		&:after {
			transform: scale(1.07);
			background: $color__theme;
			box-shadow: rem(0px 10px 20px 8px rgba(0,0,0,0.15));
		}

		> i {
			text-shadow: rem(0px -50px #fff, 0px 0px #fff);

		}
	}

	&.back-top {
		bottom: rem(30px);
		opacity: 1;
	}
}

#search-menu-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999999;
	background: rgba(0,0,0,0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.8);
	transition: all 0.3s ease-in-out;

	#search-form {
		position: relative;
		display: flex;
		width: 900px;
		border-bottom: 5px solid rgba(255,255,255,0.1);

		&:before {
			content: '';
			border-bottom: 5px solid transparent;
			transform-origin: 0% 50%;
			transform: scale3d(0, 1, 1);
			height: 5px;
			width: 100%;
			position: absolute;
			bottom: -5px;
		}
	}

	&.toggled {
		opacity: 1;
		visibility: visible;
		transform: scale(1);

		#search-form {
			&:before {
				transition: 0.5s ease-out 0.3s;
				transform: scale3d(1, 1, 1);
				border-color: $color__theme;
			}
		}
	}

	#popup-search {
		background-color: transparent;
		border: 0;
		color: #fff;
		height: 80px;
		width: 100%;
		font-size: 26px;
		padding: 10px 0;
		font-weight: 700;

		&::placeholder {
			color: #a2a2a2;
		}
	}

	button {
		background-color: transparent;
		outline: 0;
	}
}

.close-search {
	position: absolute;
	top: 30px;
	right: 30px;
	color: #fff;
	font-size: 40px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		opacity: 0.8;
	}
}