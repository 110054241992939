.banner {
	height: 95vh;
	position: relative;

	.swiper-slide {
		height: 95vh;
		background-size: cover;
		background-position: center center;

		.seaperator-line {
			max-width: 540px;
			width: 100%;
			margin: 0 auto;
			display: block;
			height: 20px;
			position: relative;
			margin-bottom: 22px;

			.star {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}


			.line {
				width: 0;
				height: 1px;
				background-color: rgba(255, 255, 255, 0.502);
				position: absolute;
				top: 50%;
				transition: all 0.8s ease-in-out;
				transition-delay: 0.35s;

				&:before {
					font-family: 'ElegantIcons';
					position: absolute;
					color: #9c8a89;
					opacity: 0;
					transition: all 0.3s ease-in-out;
					transition-delay: 0.3s;
				}
			}

			.line-left {
				right:  0;

				&:before {
					content: "\45";
					right: -10px;
					top: -16px;
				}
			}

			.line-right {
				left:  0;

				&:before {
					content: "\44";
					left: -10px;
					top: -16px;

				}
			}
		}

		&.swiper-slide-active {

			.seaperator-line {


				.line {
					width: 47%;

					&:before {
						opacity: 1;
					}

				}

			}


		}
	}

	.banner-content {
		position: absolute;
		top: 55%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);

		.sub-title {
			color: $color__theme;
			font-size: 60px;
			font-family: $font__heading-two;
			font-weight: 300;
			line-height: 80px;
		}

		.banner-title {
			color: #fff;
			font-size: 100px;
			margin-bottom: 30px;
			line-height: 80px;
			font-weight: 700;
		}

		p {
			color: #fff;
			font-size: 16px;
			margin-bottom: 40px;
			text-transform: uppercase;
			letter-spacing: 6px;
		}



	}



	.swiper-container-horizontal>.swiper-pagination-bullets {
		width: auto;

	}


	.swiper-pagination {
		position: absolute;
		left: auto !important;
		top: 50% !important;
		transform: translateY(-50%);
		bottom: auto;
		right: 60px;



		.swiper-pagination-bullet {
			display: block !important;
			margin: 0 0 20px 0 !important;
		}
	}

	&-two {
		.swiper-pagination {
			position: absolute;
			left: 50% !important;
			top: auto !important;
			transform: translatex(-50%);
			bottom: 50px;
			right: 60px;



			.swiper-pagination-bullet {
				display: inline-block !important;
				margin: 0 15px 0 0 !important;
			}
		}
	}
}

.animate-dish {
	position: absolute;
	left: 35%;
	bottom: 50px;
}

.swiper-pagination {
	position: relative;
	left: 50%;

	transform: translateX(-50%);


	.swiper-pagination-bullet {
		height: 8px;
		width: 8px;
		margin-right: 5px;
		transition: all 0.3s ease-in-out;
		background: #c5c0b5;
		opacity: 1;
		position: relative;
		margin: 0 11px !important;

		&:before {
			content: '';
			border: 1px solid $color__theme;
			left: -7px;
			right: -7px;
			top: -7px;
			bottom: -7px;
			position: absolute;
			border-radius: 50%;
			transition: all 0.3s ease-in-out;
			transform: scale(0);
		}

		&.swiper-pagination-bullet-active {
			background: $color__theme;
			opacity: 1;

			&:before {
				transform: scale(1);
			}
		}
	}
}

.pg-dark {

	.swiper-pagination {

		.swiper-pagination-bullet {

			&.swiper-pagination-bullet-active {
				background: $color__theme;
				opacity: 1;
			}
		}
	}

}



.banner-static {
	height: 95vh;
	background-size: cover;
	background-position: top right;
}

.banner-wrapper {
	max-width: 1560px;
	display: flex;
	align-items: center;
	padding:0 30px;
	margin: 0 auto;
	height: 100%;
}

.banner-static-content {
	margin-top: 70px;

	.sub-title {
		font-size: 100px;
		font-weight: 300;
		color: $color__theme;
		font-family: $font__heading-two;
	}

	.banner-title {
		font-size: 100px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 25px;
	}

	p {
		margin-bottom: 45px;
	}
}

@media (max-width: 1440px) {

	.banner-static {
		background-position: top center;
	}

	.banner-static-content {


		.sub-title {
			font-size: 80px;
		}

		.banner-title {
			font-size: 80px;
			font-weight: 700;
			line-height: 80px;
			margin-bottom: 25px;
		}

		p {
			margin-bottom: 45px;

		}
	}
}

@media (max-width: 1366px) {
	.banner {
		.banner-content {
			.sub-title {
				font-size: 40px;
			}

			.banner-title {
				font-size: 80px;
			}
		}

		.swiper-slide {
			.seaperator-line {
				width: 400px;
			}
		}
	}
}

@media (max-width: 1200px) {

	.banner-static {
		background-position: top center;
	}

	.banner-static-content {


		.sub-title {
			font-size: 60px;
		}

		.banner-title {
			font-size: 60px;
			font-weight: 700;
			line-height: 70px;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 40px;

		}
	}
}

@media (max-width: 1024px) {
	.banner {
		.banner-content {
			.sub-title {
				font-size: 30px;
				line-height: 50px;
			}

			.banner-title {
				font-size: 60px;
				line-height: 70px;
				margin-bottom: 20px;
			}

			p {
				margin-bottom: 15px;
			}
		}

		.swiper-slide {
			.seaperator-line {
				width: 300px;
				margin-bottom: 10px;
			}

			&.swiper-slide-active {
				.seaperator-line {
					.line {
						width: 42%;
					}
				}
			}
		}
	}

	.banner-static {
		height: 100vh;
	}

	.banner-static-content {

		.sub-title {
			font-size: 30px;
		}

		.banner-title {
			font-size: 35px;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 20px;
			width: 45%;

			br {
				display: none;
			}

		}
	}


}


.slider-bg {
	height: 95vh;
}

@media (max-width: 768px) {
	.banner {
		height: 100vh;

		.banner-content {
			transform: translateY(-38%);

			.sub-title {
				font-size: 20px;
				line-height: 30px;
			}

			.banner-title {
				font-size: 40px;
				line-height: 40px;
			}
		}

		.swiper-slide {
			height: 100vh;

			.seaperator-line {
				width: 250px;
			}
		}

		.swiper-pagination {
			display: none;
		}
	}

	.banner-static-content {



		p {
			br {
				display: none;
			}

		}
	}

	.animate-dish {
		display: none;

	}


}

@media (max-width: 576px) {
	.banner-static {
		background-position: 40% 0;
	}

	.banner-static-content p {
		width: 70%;
	}
}


@media (max-width: 440px) {
	.banner-static-content p {
		width: 100%;
	}
}