.search-form-widget {
	display: flex;
	background: #f8f6f2;

	label {
		margin: 0;
	}

	.search-field {
		background: transparent;
		border: 0;
		padding: 19px 10px 19px 30px;
		font-size: 20px;
		font-weight: 400;

		&::placeholder {
			color: #9e9c97;
		}
	}

	button {
		bottom: 0;
		border: 0;
		background-color: transparent;
		color: #9e9c97;
		outline: 0;
		padding-right: 30px;

		i {
			transform: rotate(-89deg);
			display: block;
		}

		&:hover {
			color: $color__theme;
		}
	}
}

.widget {
	margin-bottom: 56px;

	.widget-title {
		font-size: 24px;
		font-weight: 700;
		border-bottom: 1px dashed #dad3c5;
		padding-bottom: 13px;
		margin-bottom: 30px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			a {
				font-size: 20px;
				line-height: 40px;
				color: #827f79;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}
}


/* Recent Post */
.gp-posts-widget-wrapper {
	.post-item {
		display: flex;
		flex-wrap: wrap;

		&:not(:last-child) {
			margin-bottom: 30px;
		}

		.post-widget-thumbnail {
			width: 100px;
			margin-right: 30px;
		}

		.post-widget-info {
			flex: 1;

			.post-widget-title {
				font-size: 20px;
				font-weight: 700;
				line-height: 30px;

				a {
					color: $color__heading;

					&:hover {
						color: $color__theme;
					}
				}

			}

			.post-date {
				color: #aba8a4;
				font-size: 18px;
				line-height: 28px;
			}
		}

	}


}

/* Tags */
.tagcloud {
	a {
		font-size: 16px;
		background: #f8f6f2;
		color: #827f79;
		padding: 4px 17px;
		font-weight: 700;
		margin-bottom: 10px;
		margin-right: 4px;
		display: inline-block;

		&:hover {
			background: $color__heading;
			color: #fff;
		}

	}
}

/* Comments */
.comment-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;

	.icon {
		height: 70px;
		width: 70px;
		line-height: 77px;
		text-align: center;
		color: $color__heading;
		background: #f8f6f2;
		border-radius: 50%;
		color: #827f79;
		margin-right: 30px;
	}

	.comment-content {
		flex: 1;
	}

	.comment-content {
		h3 {
			color: $color__heading;
			font-size: 20px;
			margin-bottom: 10px;

			span {
				color: #827f79;
				font-weight: 400;
			}

		}
		p {
			line-height: 30px;

			a {
				color: #827f79;

				&:hover {
					color: $color__theme;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.search-form-widget {
		margin-top: 30px;

		label {
			width: 100%;
		}
	}
}