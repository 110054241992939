#instagram {
	padding-bottom: 10px;
}

.instagram-feed {

	a {
		position: relative;
		display: block;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.5);
			transition: all 0.3s ease-in-out;
			opacity: 0;
		}

		.inst-id {
			background: $color__heading;
			position: absolute;
			left: 0;
			bottom: 30px;
			padding: 9px 32px;
			color: #fff;
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}

		&:hover  {
			&:before, .inst-id {
				opacity: 1;
			}
		}
	}

}