.food-area {
	padding: 114px 0 116px;
}

.food-box {
	background: #fff;
	box-shadow: 0px 4px 8px 0px rgba(111, 102, 88, 0.04);
	transition: all 0.3s ease-in-out;
	margin-bottom: 40px;

	.food-feature-image {
		img {
			width: 100%;
		}
	}

	.info {
		padding: 18px 30px;
		position: relative;

		h3 {
			margin: 0;
			a {
				font-size: 20px;
				font-weight: 700;
				color: $color__heading;

				&:hover {
					color: $color__theme;
				}
			}
		}

		.price {
			position: absolute;
			top: 50%;
			right: 40px;
			transform: translateY(-50%);
			color: $color__theme;
			font-weight: 700;
		}
	}

	&:hover {
		box-shadow: 0px 20px 30px 0px rgba(111, 102, 88, 0.1);
	}
}


@media (min-width: 991px) and (max-width: 1200px) {
	.food-box .info {
		padding: 18px 20px;
	}
}

@media (max-width: 991px) {
	.food-area {
		padding: 75px 0 80px;
	}
}

@media (max-width: 768px) {
	.food-area {
		padding: 45px 0 50px;
	}
}