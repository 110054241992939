.pricing-area {
	padding: 113px 0 115px;

	.slider-pagi-inner {
		margin-top: -25px;
	}
}

.package-slider {
	padding: 0 15px;
}

.pricing-table {
	background: rgb(255, 255, 255);
	box-shadow: 0px 10px 20px 0px rgba(111, 102, 88, 0.06);
	text-align: center;
	padding: 20px 20px 40px;
	transition: all 0.3s ease-in-out;
	margin-bottom: 80px;

	.food-thumb {
		margin-bottom: 30px;
	}

	.price-header {
		.package-name {
			font-size: 34px;
			font-weight: 700;
			margin-bottom: 15px;

			a {
				color: $color__heading;

				&:hover {
					color: $color__theme;
				}
			}
		}

		.price {
			font-size: 24px;
			color: $color__theme;
			margin-bottom: 32px;
		}
	}

	.price-des {
		p {
			margin-bottom: 0;
		}

	}

	&.featured {
		position: relative;

		span {
			position: absolute;
			top: 19px;
			left: 2px;
			color: #ffffff;
			font-size: 11px;
			text-transform: uppercase;
			font-weight: 700;
			transform: rotate(-45deg);
			display: block;
			text-align: center;
			z-index: 1111;

			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border-top: 0 solid transparent;
				border-bottom: 100px solid transparent;
				border-left: 100px solid $color__theme;
				transform: rotate(45deg);
				bottom: -55px;
				right: -17px;
				z-index: -1;
			}

			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border-top: 0 solid transparent;
				border-bottom: 110px solid transparent;
				border-left: 110px solid rgba($color__theme, 0.2);
				transform: rotate(45deg);
				bottom: -67px;
				right: -21px;
				z-index: -1;
			}
		}
	}

	&:hover {
		box-shadow: 0px 50px 60px 0px rgba(111, 102, 88, 0.2);
	}
}

@media (max-width: 1200px) {
	.pricing-table {
		.price-header {
			.package-name {
				font-size: 30px;
			}
		}
	}
}


@media (max-width: 991px) {
	.pricing-area {
		padding: 70px 0;
	}
}