.contact-form-wrapper {
	background: #fff;
	box-shadow: 0px 50px 60px 0px rgba(111, 102, 88, 0.1),0px -10px 0px 0px rgba(197, 157, 95, 0.4);
	max-width: 1370px;
	margin: 0 auto;
	padding: 62px 100px 75px;
	margin-bottom: 80px;

	.google-map {
		padding-right: 40px;
	}
}

.contact-info-wrapper {
	padding: 20px 0 66px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: -100%;
		height: 400px;
		width: 100%;
		background: $color__bg;
		z-index: -1;
	}

}


.contact-infos {
	.contact-info {
		text-align: center;
		margin-bottom: 30px;

		.icon {
			font-size: 24px;
			margin-bottom: 20px;
			color: $color__theme;
			border-radius: 50%;
			background: rgb(255, 255, 255);
			box-shadow: 0px 30px 40px 0px rgba(111, 102, 88, 0.15);
			line-height: 73px;
			text-align: center;
			height: 70px;
			width: 70px;
			margin: 0 auto 25px;
		}


		.title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.content {
			p {
				font-size: 20px;
				font-weight: 500;
				color: #777;
				margin: 0;

				span {
					font-weight: 700;
				}
			}
		}
	}
}





.contact-form {
	input, textarea {
		background: #fbf9f6;
		border: 0;
		padding: 11px 25px;
		width: 100%;
		outline: 0;
		margin-bottom: 20px;
		transition: all 0.3s ease-in-out;

		&::placeholder {
			color: #9e9c97;
			transition: all 0.3s ease-in-out;
			transform-origin: 0 50%;
		}

		&:focus {
			background: #fff;
			box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.2);

			&::-webkit-input-placeholder {
				-moz-transform: scale(0.8);
				-ms-transform: scale(0.8);
				-webkit-transform: scale(0.8);
				transform: scale(0.8);

			}

		}
	}

	input {
		height: 70px;
	}

	textarea {
		height: 150px;
		margin-bottom: 20px;
	}

	&.input-light {
		input,textarea{
			background: #fff;
		}

		.submit-btn {
			display: block;
			margin: 0 auto;
		}
	}

	&.style-form {
		input, textarea {
			box-shadow: 0px 4px 8px 0px rgba(111, 102, 88, 0.04);

			&:focus {
				background: #fff;
				box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.2);
			}
		}
	}
}


.contact-infos-box {
	.contact-info {
		background: #fdfcfb;
		text-align: center;
		border: 1px solid #f8f6f2;
		padding: 40px 0 20px;
		height: 100%;

		.icon {
			font-size: 24px;
			margin-bottom: 20px;
			color: $color__theme;
			border-radius: 50%;
			background: rgb(255, 255, 255);
			box-shadow: 0px 30px 40px 0px rgba(111, 102, 88, 0.15);
			line-height: 73px;
			text-align: center;
			height: 70px;
			width: 70px;
			margin: 0 auto 25px;
		}


		.title {
			font-size: 20px;
			font-weight: 700;
		}

		.content {
			p {
				font-size: 20px;
				font-weight: 500;
				color: #777;
				margin: 0;

				span {
					font-weight: 700;
				}
			}
		}

	}
}


#contact-two {
	background: #f9f8f4;
	padding: 110px 0 77px;

	.contact-form input, .contact-form textarea {
		background: #fff;
	}

	.google-map {
		padding-right: 40px;
	}

	.section-title {
		margin-bottom: 45px;
	}
}



#contact-single {
	padding-top: 120px;
}

@include respond-below(sm) {
	#contact {
		padding-top: 30px;
	}
}


@include respond-below(xs) {
	#contact, #contact-single {
		padding-top: 30px;
	}
}

.contact-info-wrapper {
	background: #f8f6f2;
}

.form-container {
	max-width: 970px;
	margin: 0 auto;
}



.map-border {
	.gmap3-area {
		border: 10px solid #fff;
	}
}

.form-footer {
	display: flex;
	justify-content: space-between;
}

.condition {
	display: flex;
	align-items: center;

	.styled-checkbox {
		position: absolute;
		opacity: 0;
		height: 20px;
		width: 16px;
		margin: 0;
	}

	span {
		font-size: 18px;
	}
}


.styled-checkbox + label {
	position: relative;
	cursor: pointer;
	padding: 0;
	margin: 0;
	height: 20px;
}

.styled-checkbox + label:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 16px;
	height: 16px;
	// background: #f5f5f5;
	border: 2px solid #aba8a4;
	transition: all 0.3s ease-in-out;
}

.styled-checkbox:hover + label:before {
	// background: $color__theme;
	border-color: #333;
}

.styled-checkbox:checked + label:before {
	// background: $color__theme;
	border-color: #333;
}

.styled-checkbox:checked + label:after {
	content: '';
	position: absolute;
	left: 3px;
	top: 9px;
	background: #333;
	width: 2px;
	height: 2px;
	-webkit-box-shadow: 2px 0 0 #333, 4px 0 0 #333, 4px -2px 0 #333, 4px -4px 0 #333, 4px -6px 0 #333, 4px -8px 0 #333;
	box-shadow: 2px 0 0 #333, 4px 0 0 #333, 4px -2px 0 #333, 4px -4px 0 #333, 4px -6px 0 #333, 4px -8px 0 #333;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}




#reservations {
	background: $color__bg;
	padding: 110px 0 77px;
}

#reservation-two {
	padding-top: 120px;
}

@media (max-width: 1366px) {
	.contact-form-wrapper {
		max-width: 90%;
		padding: 40px 50px;

		.google-map {
			padding-right: 0;
		}
	}

	.contact-info-wrapper {
		padding: 90px 0 60px;
	}
}

@media (max-width: 991px) {

	.contact-form-wrapper {
		.google-map {
			margin-bottom: 30px;
		}
	}

	.contact-info-wrapper{
		&:before {
			top: -81%;
		}
	}

	#contact-two .google-map {
		padding: 0;
		margin-bottom: 30px;
	}

	#contact-two, #reservations {
		padding: 70px 0 50px;
	}

	.comment-area {
		padding: 80px 0;
	}

	.comment-area .coment-title {
		margin-bottom: 40px;
	}

	#reservations {
		padding: 80px 0 40px;
	}

	.wrapper-padding {
		padding-top: 50px;
	}

}

@media (max-width: 768px) {
	#contact-two {
		padding: 40px 0 40px;
	}

	#reservations {
		padding: 40px 0 20px;
	}

	.comment-area {
		padding: 50px 0;
	}


	.comment-area .coment-title {
		margin-bottom: 20px;
	}

	#contact-single {
		padding-top: 50px;
	}

	.wrapper-padding {
		padding-top: 20px;
	}


}

@media (max-width: 576px) {
	.contact-form-wrapper {
		padding: 40px 20px;
	}

	.form-footer {
		display: block;

		.condition {
			margin-bottom: 20px;
		}

	}

	.comment-form input, .comment-form textarea {
		padding: 11px 20px;
		font-size: 16px
	}

	.comment-form textarea {
		height: 120px;
	}

	.contact-form input {
		height: 55px;
	}

	.comment-form .comment-form-author, .comment-form .comment-form-email {
		width: 100%;
		padding: 0;
	}
}

