
.team-member {
	margin-bottom: 30px;
	transition: all 0.3s ease-in-out;

	.member-image {
		position: relative;


		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.7);
			transition: all 0.3s ease-in-out;
			opacity: 0;
		}

		.member-cont {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			text-align: center;
			padding: 0 30px;

			.separator {
				display: block;
				width: 100%;
				margin: 20px 0 15px;
				position: relative;
				height: 1px;

				&:before {
					content: '';
					position: absolute;
					background: rgba(255,255,255,0.5);
					height: 1px;
					transition: all 0.3s ease-in-out;
					width: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			.member-link {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					margin-right: 5px;

					a {
						display: block;
						height: rem(50px);
						width: rem(50px);
						color: #fff;
						text-align: center;
						line-height: rem(50px);
						border: 1px solid #fff;
						border-radius: 50%;
						transform: scale(0.5);
						opacity: 0;
						transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);



						&:hover {
							background: #fff;
							color: $color__heading;
						}
					}

					&:nth-child(2) a {
						transition-delay: 0.1s;
					}

					&:nth-child(3) a {
						transition-delay: 0.2s;
					}
				}
			}

			p {
				margin: 0;
				color: #fff;
				transform: translateY(20px);
				transition: all 0.3s ease-in-out;
				opacity: 0;
			}
		}

		&:hover {
			&:before {
				opacity: 1;
			}

			.member-cont {
				.separator {
					&:before {

						width: 100%;
					}
				}

				.member-link {
					li {
						a {
							transform: scale(1);
							opacity: 1;
						}
					}
				}

				p {
					transform: translateY(0);
					opacity: 1;
				}
			}
		}
	}

	.member-bio {
		padding-top: 25px;

		.name {
			font-size: rem(24px);
			font-weight: 700;
			margin-bottom: 5px;
		}

		.job {
			display: block;
			margin-bottom: 25px;
		}

		.des {
			margin: 0;
			opacity: 1;
		}
	}

	&.style-two {
		box-shadow: 0px 4px 8px 0px rgba(111, 102, 88, 0.1);


		.member-bio {
			padding: 30px 40px;

			.job {
				margin: 0;
			}
		}

		&:hover {
			box-shadow: 0px 20px 30px 0px rgba(111, 102, 88, 0.1);
		}
	}
}

.staff-area {
	padding: 110px 0 90px;
}


@media (max-width: 991px) {
	.staff-area {
		padding: 75px 0 60px;
	}

}

@media (max-width: 768px) {
	.team-member {
		.member-bio {
			padding-top: 15px;

			.job {
				margin-bottom: 15px;
			}
		}
	}

	.staff-area {
		padding: 45px 0 30px;
	}
}