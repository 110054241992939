%btn-style {

	padding: rem(11px 34px);
	display: inline-block;
	font-size: rem(14px);
	font-weight: 700;
	text-transform: uppercase;
	background:	$color__heading;
	border: 0;
	position: relative;
	// border: rem(1px solid #222);
	// background-image: linear-gradient(30deg, $color__heading 50%, transparent 50%);
	// background-size: 540px;
	// background-repeat: no-repeat;
	// background-position: 0;
	z-index: 222;
	overflow: hidden;
}

.gp-btn {
	@extend %btn-style;
	color: #fff;

	&:before {
		width: 100%;
		height: 0;
		top: 50%;
		left: 50%;
		background: $color__theme;
		opacity: 0;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		content: '';
		position: absolute;
		z-index: -1;
		transition: all 0.3s;
	}


	&:hover {
		// background-position: 100%;
		// background-color: transparent;
		color: #fff;

		&:before {
			height: 400%;
			opacity: 1;
		}

	}

	&.nav-btn {
		// background-image: linear-gradient(30deg, $color__theme 50%, transparent 50%);
		// border-color: $color__theme;
		background: $color__theme;

		&:before {
			background: #fff;
		}

		&:hover {

			// border-color: $color__theme;
			color: $color__theme;

		}
	}

	&.btn-light {
		// background-image: linear-gradient(30deg, #fff 50%, transparent 50%);
		// border-color: #fff;
		color: #222;
		background: #fff;

		&:hover {
			color: #fff;
			box-shadow: none;

		}

		&:focus, &:active {
			box-shadow: none !important;
		}
	}
}

.gp-btn-outline {
	@extend %btn-style;

	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 100%;
		top: 0;
		right: 0;
		z-index: -1;
		background: $color__theme;
		transition: all 0.3s ease;
	}

	&:hover {
		color: #fff;
		border-color: $color__theme;

		&:after {
			left: 0;
			width: 100%;

		}
	}
}




.gp-btn-two {
	border: rem(1px solid #000);
	z-index: 1;
	padding: rem(10px 25px);
	font-weight: 500;
	background: transparent;
	outline: none !important;
	cursor: pointer;
	transition: all 0.3s ease;
	position: relative;
	display: inline-block;

	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: $color__theme;
		transition: all 0.3s ease;
	}

	&:hover {
		color: #fff;
		border-color: $color__theme;

		&:after {
			top: 0;
			height: 100%;

		}
	}
}

@media (max-width: 768px) {
	.gp-btn {
		padding: rem(7px 32px);
	}
}