.gallery {
	padding: 120px 0;
}

.cafe-gallery-items {
	width: calc(100% + 1%);
}

.grid-item {
	padding-right: 10px;
	margin-bottom: 10px;
}

.width-large {
	width: 57.27%;
}

.width-sm-large {
	width: 42.2%;
}

.width2 {
	width: 32.9%;
}

.width3 {
	width: 38.8%;
}

.width4 {
	width: 27.8% ;
}

.width-large2 {
	width: 42.8%;
}

.cafe-gallery-items {


	.grid-sizer {
		width: 1px;
	}

	.cafe-gallery-item {


		.cafe-isotope-grid__img {

			position: relative;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(0,3,10,0.6);
				opacity: 0;
				transition: all 0.3s ease-in-out;
			}

			.gallery-info {
				text-align: center;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease-in-out;

				.gallery-title {
					font-size: 20px;
					font-weight: 700;
					line-height: 30px;
					transform: translateY(10px);
					transition: all 0.3s ease-in-out;
					margin: 0;

					a {
						color: #fff;

						&:hover {
							color: #ddd;
						}
					}
				}

				.gallery-categorie {
					color: #fff;
					font-size: 16px;
					transform: translateY(-10px);
					display: block;
					font-weight: 400;

				}

				.popup-modal{
					height: 60px;
					width: 60px;
					border: 2px solid #fff;
					text-align: center;
					line-height: 63px;
					color: #fff;
					display: block;
					border-radius: 50%;
					margin: 0 auto 30px;
					font-size: 20px;

					&:hover {
						background: $color__theme;
						color: #fff;
						border-color: $color__theme;
					}
				}

				.links {
					height: 40px;
					width: 40px;
					background: #fff;
					text-align: center;
					line-height: 40px;
					color: $color__theme;
					display: block;
					border-radius: 50%;
					margin: 0 auto 20px;
					font-size: 14px;
				}
			}

			&:hover {
				&:before {
					opacity: 1;
				}

				.gallery-info {
					opacity: 1;
					visibility: visible;

					.gallery-title, .gallery-categorie {
						transform: translateY(0);
					}
				}
			}
		}
	}

	&.content-ps {
		.cafe-gallery-item {
			.gallery-info {
				top: auto;
				bottom: 0;
				transform: translateY(-50%);

			}
		}
	}
}



.width-sm {
	width: 28.5%;
}

.cafe-isotope-filter {
	padding: 0;
	list-style: none;
	margin-bottom:  30px;
	text-align: center;

	li {
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 20px;

		a {
			display: block;
			padding: 5px 22px;
			color: #222;
			font-weight: 700;
			font-size: rem(18px);
			border-radius: 3px;
			background: $color__bg;
			border: 1px solid #f9f8f4;

			&:hover {
				color: $color__theme;
				background: #fff;
				box-shadow: 0px 20px 30px 0px rgba(111, 102, 88, 0.14);
			}

		}

		&.current {
			a {
				color: $color__theme;
				background: #fff;
				box-shadow: 0px 20px 30px 0px rgba(111, 102, 88, 0.14);
			}
		}
	}
}


/* gallery Single */

.slider-wrapper {
	max-width: 770px;
	margin: 0 auto;
	position: relative;
	margin-bottom: 120px;

	.slider-nav {
		position: absolute;
		width: 100%;
		top: 50%;
		margin: 0;
		left: 0;
		display: block;

		.slider-next, .slider-prev {
			position: absolute;
		}

		.slider-next {
			right: -130px;
			margin: 0;
		}

		.slider-prev {
			left: -130px;
		}
	}
}
.gallery-slider {
	max-width: 770px;
	margin: 0 auto;
}

.gallery-details {
	margin: 0;
	padding: 0;
	list-style: none;
	max-width: 375px;

	li {
		line-height: 60px;
		border-bottom: 1px solid #eee;

		span {
			font-weight: 600;
			color: #222;
			display: inline-block;
			width: 110px;
		}
	}
}

.details-content {


	p {
		margin: 0;
	}
}


.related-items {
	h2 {
		font-size: 36px;
		font-weight: 600;
		color: #222;
		margin-bottom: 20px;
	}
}

.related-item {
	position: relative;
	overflow: hidden;

	.content {
		width: 100%;
		background: rgba(0,3,10,0.6);
		padding: 25px 0;
		position: absolute;
		left: 0;
		bottom: -100px;
		width: 100%;
		text-align: center;
		transition: all 0.3s ease-in-out;

		h3 {
			font-size: 20px;
			margin: 0;
			a {
				color: #fff;

				&:hover {
					color: $color__theme;
				}
			}
		}

		a {
			color: #fff;
		}
	}

	&:hover {
		.content {
			bottom: 0;

		}
	}
}



@media (max-width: 991px) {
	.width3, .width2, .width4{
		width: 48%;
	}

	.gallery {
		padding: 80px 0;
	}

	.mt-60 {
		margin-top: 30px !important;
	}

}

@media (max-width: 768px) {
	.gallery {
		padding: 50px 0;
	}

	.width3, .width2, .width4{
		width: 100%;

		img {
			width: 100%;
		}
	}

	.width-large2, .width-sm-large, .width-large, .large2 {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.grid-item {
		width: 100%;

		img {
			width: 100%;
		}
	}

}

@media (max-width: 576px) {
	// .cafe-isotope-filter {
	// 	flex-wrap: wrap;

	// 	li {
	// 		width: 100%;
	// 		flex: auto;
	// 	}
	// }
}

@media (max-width: 420px) {
	.cafe-gallery-items {
		.cafe-gallery-item {
			.cafe-isotope-grid__img {
				.gallery-info {
					.popup-modal {
						height: 40px;
						width: 40px;
						border: 2px solid #fff;
						text-align: center;
						line-height: 40px;
						color: #fff;
						display: block;
						border-radius: 50%;
						margin: 0 auto 15px;
						font-size: 14px;
					}
				}

				.gallery-title {
					font-size: 18px;
				}
			}
		}
	}
}