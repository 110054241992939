#blog-grid {
	padding: 110px 0 94px;
	.blog-post {
		margin-bottom: 30px;

		.feature-image {
			a {
				display: block;
				position: relative;
				overflow: hidden;

				img {
					transition: all 0.3s ease-in-out;
					width: 100%;
				}

				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.3);
					opacity: 0;
					transition: all 0.3s ease-in-out;
					z-index: 22;
				}



				&:hover {
					&:before {
						opacity: 1;
					}

					img {
						transform: scale(1.05)
					}
				}
			}
		}
	}
}

.blog-content {
	padding-top: 30px;

	.post-meta {
		margin: 0;
		padding: 0;
		list-style: none;


		li {
			display: inline-block;
			position: relative;


			a {
				color: $color__body;
				font-weight: 400;

				&:hover {
					color: $color__theme;
				}
			}
			&:not(:last-child) {
				margin-right: 25px;
				&:after {
					content: '';
					position: absolute;
					right: -17px;
					top: 5px;
					width: 2px;
					height: 18px;
					background: $color__body;

				}
			}
		}
	}

	.entry-title {
		font-size: 30px;
		font-weight: 600;
		line-height: 44px;
		margin: 15px 0;

		a {
			color: #222;

			&:hover {
				color: $color__theme;
			}
		}
	}

	.read-more-ntn {
		font-size: 12px;
		font-weight: 600;
		color: $color__theme;
		text-transform: uppercase;
		display: flex;

		i {
			font-size: 10px;
			display: flex;
			align-items: center;
			margin-left: 5px;
			transition: all 0.3s ease-in-out;
		}

		&:hover {
			color: darken($color__theme, 10%);

			i {
				margin-left: 8px;
			}
		}
	}
}

.post-wrapper {
	max-width: 670px;

	.categories {
		position: absolute;
		top: 40px;
		right: 0;
	}

	.category-item {
		font-size: 16px;
		font-weight: 700;
		background: #f8f6f2;
		padding: 9px 17px;
		color: #827f79;
		margin-left: 5px;

		&:hover {
			background: $color__heading;
			color: #fff;
		}

	}

	.post {

		margin-bottom: 70px;

		&:last-child {
			.post {
				margin-bottom: 0;
			}
		}

	}



}


.blog-post-archive {
	padding: 120px 0;
}

.post {

	.blog-content {
		padding: 40px 0;
		position: relative;
		border-bottom: 1px solid #e6e5e0;


		.post-meta {
			li {

				&:after {
					background: #b8b5b2;
					height: 16px;
					top: 8px;
				}

				a {
					color: #aba8a4;
					font-size: 18px;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}

		.entry-title {
			margin: 15px 0 24px;
			font-size: 40px;
			font-weight: 700;
		}

		p {
			margin-bottom: 30px;
		}

		.read-more {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			color: $color__heading;

			&:hover {
				color: $color__theme;
			}
		}
	}

	.author {
		position: absolute;
		right: 0;
		bottom: 40px;

		img {
			height: 40px;
			width: 40px;
			border-radius: 50%;
		}

		span {
			font-size: 16px;
			font-weight: 700;
		}

		a {
			font-size: 16px;
			font-weight: 700;
			color: $color__heading;

			&:hover {
				color: $color__theme;
			}
		}
	}

	&.video-post {
		.feature-image {
			position: relative;

			.video-btn {
				height: 80px;
				width: 80px;
				background: #fff;
				border-radius: 50%;
				line-height: 80px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;

				&:before {
					content: '';
					position: absolute;
					left: -10px;
					right: -10px;
					bottom: -10px;
					top: -10px;
					border: 1px solid #fff;
					border-radius: 50%;
				}


				i {
					color: $color__heading;
					font-size: 16px;
					font-size: 40px;
					line-height: 2.05;
				}

			}
		}
	}



	&.link-post {
		.blog-content {
			background: #faf8f5;
			padding: 41px;
			border-bottom: 0;
			position: relative;
			overflow: hidden;


			&:before, &:after {
				content: "\e02c";
				font-family: 'ElegantIcons';
				position: absolute;
			}

			&:before {
				left: 28px;
				top: 24px;
				color: #f3f0eb;
				font-size: 50px;
				z-index: 1;
			}

			&:after {
				right: 0;
				bottom: -3px;
				font-size: 100px;
				color: #e7e2da;
			}

			p {
				font-size: 24px;
				font-weight: 700;
				position: relative;
				z-index: 2;
				margin-bottom: 8px;
				line-height: 40px;

				a {
					color: #827f79;

					&:hover {
						color: $color__theme;
					}
				}
			}
		}
	}
}


.quote-post {
	position: relative;



	background: #faf8f5;
	padding: 41px;
	border-bottom: 0;

	&:before {
		content: '';
		position: absolute;
		left: 28px;
		top: 20px;
		background-image: url(../../media/blog/quote2.png);
		width: 90px;
		height: 60px;
		z-index: 1;

	}

	p {
		font-size: 24px;
		font-weight: 700;
		line-height: 40px;
		margin-bottom: 35px;
		position: relative;
		z-index: 2;
	}

	span {
		font-size: 16px;
		font-weight: 700;
		color: $color__heading;
	}


	.quote {
		position: absolute;
		right: 50px;
		bottom: 35px;
	}
}

.post-navigation {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;

	li {
		display: inline-block;
		height: 50px;
		width: 50px;
		line-height: 50px;
		font-size: 20px;
		background: #f8f6f2;
		border-radius: 50%;
		margin-right: 10px;
		text-align: center;
		transition: all 0.3s ease-in-out;


		a {
			display: block;

		}

		&.next {
			line-height: 54px;
		}

		&:hover {
			background: $color__theme;

			a {
				color: #fff
			}
		}

		&.active {
			background: $color__theme;
			color: #fff;

		}
	}
}


.blog-single {
	padding-top: 120px;

	.post {
		margin-bottom: 20px;

		.blog-content {
			padding: 40px 0 20px;

			p {
				margin-bottom: 45px;
			}

			h3 {
				margin-top: 42px;
				font-size: 40px;
				font-weight: 700;
				margin-bottom: 20px;
			}
		}
	}

	blockquote {
		margin-bottom: 42px;
		display: block;

	}

	.tagcloud {
		span {
			color: #aba8a4;
			display: inline-block;
			margin-right: 15px;
		}
		a {
			line-height: 25px;
		}
	}
}

.blog-share {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.share-title p {
		color: #aba8a4;
		display: inline-block;
		margin: 0;
		font-weight: 700;

	}
}

.share-link {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 5px;

		&:last-child {
			margin-right: 0;
		}

		a {
			display: block;
			height: 44px;
			width: 44px;
			text-align: center;
			line-height: 45px;
			background: #f8f6f2;
			color: #aba8a4;
			border-radius: 50%;
			font-size: 14px;

			&:hover {
				background: $color__theme;
				color: #fff;
			}
		}
	}
}

.cafedia_post_author_box {
	background: #faf8f5;
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 80px;

	.profile_image {
		width: 80px;
		margin-right: 20px;
		border-radius: 50%;

		img {
			border-radius: 50%;
		}

	}

	.profile_content {
		flex: 1;

		.profile_name {
			font-size: 20px;
			line-height: 28px;
			color: $color__heading;
			margin: 0;
		}

		.author-job {
			font-size: 16px;
			line-height: 28px;
			margin-bottom: 15px;
			display: inline-block;
		}

		p {
			font-size: 20px;
			color: #827f79;
			margin: 0;
		}
	}
}

.comment-area {
	padding: 110px 0 120px;

	.coment-title {
		font-size: 30px;
		font-weight: 700;
		color: $color__heading;
		margin-bottom: 70px;
	}
}


.comment-list {
	margin: 0;
	padding: 0;
	list-style: none;

	.comment {

		.comment-body {
			display: flex;
			margin-bottom: 40px;


			.comment-author {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				margin-right: 30px;

				img {
					border-radius: 50%;
				}
			}

			.comment-content {
				border-bottom: 1px solid #e6e5e0;
				padding-bottom: 30px;
				position: relative;

				.ratings {
					position:  absolute;
					top: 0;
					right: 0;
				}


				h4 {
					margin: 0;
					font-size: 20px;
				}

				.comment-reply-link {
					color: #827f79;
					font-size: 16px;
					font-weight: 700;

					i {
						margin-right: 10px;
					}

					&:hover {
						color: $color__theme;
					}
				}

				.comment-metadata {
					margin-bottom: 10px;

					a {
						font-size: 16px;
						color: $color__heading;
					}
				}
			}

			&.menu-comments {
				.comment-author {
					width: 100px;
					height: 100px;

					img {
						width: 100%;
					}
				}
			}
		}




	}

	.children {
		margin: 0;
		padding-left: 150px;
		list-style: none;
	}


}

.comment-respond {
	.comment-reply-title {
		font-size: 50px;
		font-weight: 700;
		margin-bottom: 68px;
	}
}

.comment-form {
	.comment-form-author, .comment-form-email {
		width: 50%;
		float: left;
		margin-bottom: 20px;
	}

	.comment-form-author {
		padding-right: 15px;
	}

	.comment-form-email {
		padding-left: 15px;
	}

	input, textarea {
		background: #fff;
		border: 0;
		box-shadow: 0px 4px 8px 0px rgba(111, 102, 88, 0.04);
		font-size: 18px;
		padding: 19px 25px;

		&::placeholder {
			color: #9e9c97;
			transition: all 0.3s ease-in-out;
		}

		&:focus {
			box-shadow: 0px 20px 40px 0px rgba(111, 102, 88, 0.2);
		}
	}

	textarea {
		height: 200px;
	}


}

.ratings {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		font-size: 12px;
		color: #f0ae0f;
	}
}


@media (max-width: 991px) {
	#blog-grid {
		padding: 80px 0 50px;
	}

	.comment-area {
		padding: 75px 0 80px;
	}
}

@media (max-width: 768px) {
	#blog-grid {
		padding: 40px 0 30px;
	}

	.comment-area {
		padding: 45px 0 50px;
	}

	.comment-list {
		.comment {
			.comment-body {
				&.menu-comments {
					.comment-author {
						height: 60px;
						width: 60px;
						margin-right: 15px;
					}
				}

				.comment-author {
					height: 60px;
					width: 60px;
					margin-right: 15px;
				}

				.comment-content {
					padding-bottom: 20px;

					h4 {
						font-size: 18px;
					}

					p {
						font-size: 16px;
						line-height: 26px;
					}
				}


			}
		}

		.children {
			padding-left: 70px;
		}
	}

	.comment-respond .comment-reply-title {
		font-size: 35px;
		margin-bottom: 30px;
	}
}

@media (max-width: 420px) {
	.comment-list .comment .comment-body .comment-content .ratings {
		position: static;
	}
}